var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { NeonButton, NeonDateField, NeonMultiSelectField } from '@ps-refarch-ux/neon';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ModalLoaderContext, SidePanelViewContext, SchoolYearContext, GradesContext, FilterContext } from '../../../../common/context/smart-group-context';
import './settings.scss';
import { ConsequenceType } from '../../../../common/constants';
import { DateHelper } from '../../../../common/helper/date-helper';
import { AllGradesOption } from './settings-config';
import { StartValue, EndValue, getContinuousDateValidationMessage, getSchoolYearStartDateValidationError, getSchoolYearEndDateValidationError } from '../../../../common/constants';
var dateFromType = 'from';
var dateToType = 'to';
var dateRosterType = 'rosterDate';
export default function Settings(_a) {
    var consequenceType = _a.consequenceType, settingsApplyHandler = _a.settingsApplyHandler;
    var modalLoaderContext = useContext(ModalLoaderContext);
    var gradesContext = useContext(GradesContext);
    var sidePanelViewContext = useContext(SidePanelViewContext);
    var schoolYearContext = useContext(SchoolYearContext);
    var filterContext = useContext(FilterContext);
    var gradesOptionsRef = useRef([]);
    var gradesDataDropDownTextRef = useRef('');
    var _b = useState(undefined), fromDate = _b[0], setFromDate = _b[1];
    var _c = useState(undefined), toDate = _c[0], setToDate = _c[1];
    var _d = useState(undefined), selectedGrades = _d[0], setSelectedGrades = _d[1];
    var _e = useState(undefined), rosterDate = _e[0], setRosterDate = _e[1];
    var _f = useState({
        fromDateError: '',
        toDateError: ''
    }), continiousDateError = _f[0], setContiniousDateError = _f[1];
    var _g = useState(false), isFormValid = _g[0], setIsFormValid = _g[1];
    // showing modal loader if grades and school year data are not fetched when settings drawer is opened
    useEffect(function () {
        if (gradesContext.loading || schoolYearContext.loading) {
            modalLoaderContext.setModalLoaderView('before-settings');
        }
        else {
            modalLoaderContext.setModalLoaderView('');
            // Add 'all-grades' to the list of grades.
            gradesOptionsRef.current = __spreadArray([AllGradesOption], gradesContext.grades, true);
            // if all grades are selected, then select 'all-grades' option
            if (gradesContext.grades.length && filterContext.filterSelectedGrades.length === gradesContext.grades.length) {
                setSelectedGrades(__spreadArray([AllGradesOption.name], filterContext.filterSelectedGrades, true));
            }
            else {
                setSelectedGrades(filterContext.filterSelectedGrades);
            }
            gradesDataDropDownTextRef.current = "".concat(filterContext.filterSelectedGrades.length, " selected");
            setFromDate(filterContext.filterFromDate);
            setToDate(filterContext.filterToDate);
            setRosterDate(filterContext.filterRosterDate);
        }
    }, [gradesContext.loading, schoolYearContext.loading]);
    // validating form fields whenever form fields are updated for continous date and fixed date
    useEffect(function () {
        if (consequenceType === ConsequenceType.Continuous) {
            setIsFormValid(selectedGrades !== undefined && !continiousDateError.fromDateError && !continiousDateError.toDateError);
        }
        else {
            setIsFormValid(selectedGrades !== undefined);
        }
    }, [selectedGrades, continiousDateError]);
    var validateDateInput = function (date, comparisonDate, dateType) {
        var errorMessage = '';
        if (date && comparisonDate && ((dateType === EndValue && date < comparisonDate) || (dateType === StartValue && date > comparisonDate))) {
            errorMessage = getContinuousDateValidationMessage(dateType);
        }
        else if (date && date < DateHelper.getDate(schoolYearContext.start_date)) {
            errorMessage = getSchoolYearStartDateValidationError(dateType);
        }
        else if (date && date > DateHelper.getDate(schoolYearContext.end_date)) {
            errorMessage = getSchoolYearEndDateValidationError(dateType);
        }
        return errorMessage;
    };
    var validateDateToInput = function (date) {
        return validateDateInput(date, fromDate, EndValue);
    };
    var validateDateFromInput = function (date) {
        return validateDateInput(date, toDate, StartValue);
    };
    // Checking if date is in school year range (if we have the school year data) and setting error accordingly
    var checkAndSetDate = function (date, type) {
        setContiniousDateError({ fromDateError: '', toDateError: '' });
        var startDate = DateHelper.getDate(schoolYearContext.start_date);
        var endDate = DateHelper.getDate(schoolYearContext.end_date);
        if (type === dateFromType) {
            var errorMessageFrom = validateDateFromInput(date);
            errorMessageFrom.length > 0 ? setContiniousDateError(__assign(__assign({}, continiousDateError), { fromDateError: errorMessageFrom })) : setContiniousDateError(__assign(__assign({}, continiousDateError), { fromDateError: '' }));
            if (date === undefined || date < startDate) {
                setFromDate(startDate);
            }
            else {
                setFromDate(date);
            }
        }
        else if (type === dateToType) {
            var errorMessageTo = validateDateToInput(date);
            errorMessageTo.length > 0 ? setContiniousDateError(__assign(__assign({}, continiousDateError), { toDateError: errorMessageTo })) : setContiniousDateError(__assign(__assign({}, continiousDateError), { toDateError: '' }));
            if (date === undefined || date > endDate) {
                setToDate(endDate);
            }
            else {
                setToDate(date);
            }
        }
        else {
            if (date === undefined || date < startDate || date > endDate) {
                setRosterDate(date === undefined ? filterContext.todayDate : (date < startDate ? startDate : endDate));
            }
            else {
                setRosterDate(date);
            }
        }
    };
    // Closing side panel and sorting the data
    var onApplyHandler = function (event) {
        event.preventDefault();
        sidePanelViewContext.setSidePanelView('');
        // Remove 'all-grades' from the selected grades list if it's there.
        var selectedGradesForFilter = selectedGrades ? selectedGrades.filter(function (selectedGrade) { return selectedGrade !== AllGradesOption.name; }) : [];
        filterContext.setFilterContext(selectedGradesForFilter, fromDate || filterContext.todayDate, toDate || filterContext.todayDate, rosterDate || filterContext.todayDate);
        settingsApplyHandler(consequenceType || '', selectedGradesForFilter, fromDate || filterContext.todayDate, toDate || filterContext.todayDate);
    };
    var buttonProps = isFormValid ? {
        disabled: false,
    } : {
        disabled: true,
        dataTooltipText: 'Enter all the data before confirming'
    };
    var onGradesDropdownUpdate = function (newSelectedGrades) {
        // If no new selected grades, update and return.
        if (!newSelectedGrades) {
            setSelectedGrades(newSelectedGrades);
            gradesDataDropDownTextRef.current = '0 selected';
            return;
        }
        // Check if "all-grades" is selected.
        var allSelected = newSelectedGrades.includes(AllGradesOption.name);
        var selectedValuesIncludeAll = selectedGrades && selectedGrades.includes(AllGradesOption.name);
        if (allSelected) {
            // Calculate selected count excluding "all-grades".
            var selectedCount = selectedValuesIncludeAll ? newSelectedGrades.length - 1 : gradesOptionsRef.current.length - 1;
            // Update text to display the selected count.
            gradesDataDropDownTextRef.current = "".concat(selectedCount, " selected");
            // Handle "all-grades" selection.
            setSelectedGrades(selectedValuesIncludeAll
                ? newSelectedGrades.filter(function (option) { return option !== AllGradesOption.name; })
                : gradesOptionsRef.current.map(function (option) { return option.id; }));
        }
        else if (newSelectedGrades.length === gradesOptionsRef.current.length - 1) {
            // Update text to display the selected count based on whether "all-grades" was included.
            gradesDataDropDownTextRef.current = selectedValuesIncludeAll ? '0 selected' : "".concat(gradesOptionsRef.current.length - 1, " selected");
            // Handle the case where all options except "all-grades" are selected.
            setSelectedGrades(selectedValuesIncludeAll
                ? undefined
                : gradesOptionsRef.current.map(function (option) { return option.id; }));
        }
        else {
            // Update text to display the selected count.
            gradesDataDropDownTextRef.current = "".concat(newSelectedGrades.length, " selected");
            // Handle other cases where not all options are selected.
            setSelectedGrades(__spreadArray([], newSelectedGrades, true));
        }
    };
    var gradesComponent = React.createElement("div", null,
        React.createElement(NeonMultiSelectField, { id: "mfe-beh-smart-groups-settings-grades", dataLabelText: "Grades", dataDropDownText: gradesDataDropDownTextRef.current, dataShowLabel: "true", dataIsFieldOnly: "true", options: gradesOptionsRef.current, modelValue: selectedGrades, dataShowSelectedItemsList: false, dataIsRequired: false, modelValueChange: function (newSelectedGrades) {
                onGradesDropdownUpdate(newSelectedGrades);
            }, className: "mfe-beh-smart-groups-settings-grades ".concat(consequenceType === ConsequenceType.Fixed && 'mfe-beh-smart-groups-settings-grades-fixed') }));
    var studentAddedDateComponent = React.createElement("div", { className: 'mfe-beh-smart-groups-settings-students-added' },
        React.createElement("div", { className: 'mfe-beh-smart-groups-settings-students-added-label' }, "Date"),
        React.createElement("div", { className: 'mfe-beh-smart-groups-settings-students-added-subtext' }, "Select the date the students were added."),
        React.createElement("div", { className: 'mfe-beh-smart-groups-settings_continious_date' },
            React.createElement("div", { className: 'mfe-beh-smart-groups-settings_continious_date_child' },
                React.createElement(NeonDateField, { id: 'mfe-beh-smart-groups-settings-start-date', dataLabelText: 'From', dataShowLabel: true, dataIsRequired: false, dataPlaceholderText: 'MM/DD/YYYY', modelValue: fromDate, modelValueChange: function (date) {
                        checkAndSetDate(date, dateFromType);
                    }, className: 'mfe-beh-smart-groups-settings-start-date' }),
                (continiousDateError === null || continiousDateError === void 0 ? void 0 : continiousDateError.fromDateError.length) > 0 && React.createElement("span", { className: 'mfe-beh-smart-groups-settings-date-error', "aria-live": 'assertive', "aria-relevant": 'additions removals' }, continiousDateError === null || continiousDateError === void 0 ? void 0 : continiousDateError.fromDateError)),
            React.createElement("div", { className: 'mfe-beh-smart-groups-settings_continious_date_child' },
                React.createElement(NeonDateField, { id: 'mfe-beh-smart-groups-settings-end-date', dataLabelText: 'To', dataShowLabel: true, dataIsRequired: false, dataPlaceholderText: 'MM/DD/YYYY', modelValue: toDate, dataMinValue: DateHelper.getDate(schoolYearContext.start_date).toDateString(), dataMaxValue: DateHelper.getDate(schoolYearContext.end_date).toDateString(), modelValueChange: function (date) {
                        checkAndSetDate(date, dateToType);
                    }, className: 'mfe-beh-smart-groups-settings-end-date' }),
                (continiousDateError === null || continiousDateError === void 0 ? void 0 : continiousDateError.toDateError.length) > 0 && React.createElement("span", { className: 'mfe-beh-smart-groups-settings-date-error', "aria-live": 'assertive', "aria-relevant": 'additions removals' }, continiousDateError === null || continiousDateError === void 0 ? void 0 : continiousDateError.toDateError))));
    var rosterDateComponent = React.createElement(NeonDateField, { id: 'mfe-beh-smart-groups-settings-roster-date', dataLabelText: 'Roster date', dataShowLabel: true, dataIsRequired: false, dataPlaceholderText: 'MM/DD/YYYY', modelValue: rosterDate, modelValueChange: function (date) {
            checkAndSetDate(date, dateRosterType);
        }, className: 'mfe-beh-smart-groups-settings-roster-date' });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-slot": 'drawer-header-title' }, "Filter"),
        React.createElement("div", { "data-slot": 'drawer-body' },
            React.createElement("div", { className: 'mfe-beh-smart-groups-settings-filters' }, consequenceType === ConsequenceType.Continuous ?
                React.createElement(React.Fragment, null,
                    gradesComponent,
                    studentAddedDateComponent) :
                React.createElement(React.Fragment, null,
                    rosterDateComponent,
                    gradesComponent))),
        React.createElement("div", { "data-slot": 'drawer-footer-content' },
            React.createElement("div", { className: 'neon-2_8_0-button-layout' },
                React.createElement(NeonButton, { id: 'mfe-beh-smart-groups-settings-cancel', dataText: 'Cancel', dataType: 'Default', onClick: function (event) {
                        event.preventDefault();
                        sidePanelViewContext.setSidePanelView('');
                    } }),
                React.createElement("div", null,
                    React.createElement(NeonButton, __assign({ id: 'mfe-beh-smart-groups-settings-Apply', dataText: 'Apply', dataType: 'primary', onClick: onApplyHandler }, buttonProps)))))));
}
