// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-smg__dialog_body {
  color: var(--text-color-dark, #333);
  font: var(--neon-2_8_0-font-3);
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./common/components/modal-dialog/modal-dialog.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,8BAAA;EACA,WAAA;AACJ","sourcesContent":[".__mfe__smg__dialog_body{\n    color: var(--text-color-dark, #333);\n    font: var(--__neon__font-3);\n    margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
