// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-add-reason {
  width: 410px;
  margin-block-end: var(--neon-2_8_0-spacing-large);
  margin-inline-end: var(--neon-2_8_0-spacing-x-large);
}

.mfe-beh-smart-groups-add-group-student {
  width: 337px;
  margin-block-end: var(--neon-2_8_0-spacing-large);
}

.mfe-beh-smart-groups-add-date-error {
  box-sizing: border-box;
  color: #e21707;
  font: 400 14px/20px Roboto, Arial, Helvetica, sans-serif;
  margin: 2px 0 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./components/smart-groups/smart-group-table/add-student/add-student.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iDAAA;EACA,oDAAA;AACJ;;AAEA;EACI,YAAA;EACA,iDAAA;AACJ;;AAEA;EACI,sBAAA;EACA,cAAA;EACA,wDAAA;EACA,eAAA;EACA,UAAA;AACJ","sourcesContent":[".__mfe__add-reason{\n    width: 410px;\n    margin-block-end: var(--__neon__spacing-large);\n    margin-inline-end: var(--__neon__spacing-x-large);\n}\n\n.__mfe__add-group-student{\n    width: 337px;\n    margin-block-end: var(--__neon__spacing-large);\n}\n\n.__mfe__add-date-error{\n    box-sizing: border-box;\n    color: #e21707;\n    font: 400 14px/20px Roboto,Arial,Helvetica,sans-serif;\n    margin: 2px 0 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
